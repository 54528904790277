<template>
  <div class="show-package">
    <loading v-if="isLoading" />
    <div v-else>
      <b-row class="academic-courses-header">
        <p class="academic-courses-title">
          {{ data.name }}
        </p>
        <hr class="border-2" />
      </b-row>
      <Card v-for="(item, index) in data.courses" :key="index" :item="item" />
    </div>
  </div>
</template>

<script>
import { BRow } from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import Card from "@/components/classrooms/enrolledCourse/packageCard/card.vue";

export default {
  name: "ShowPackage",
  components: {
    BRow,
    loading,
    Card,
  },
  data() {
    return {
      isLoading: null,
      data: [],
    };
  },
  created() {
    this.getPackageCourses();
  },
  methods: {
    async getPackageCourses() {
      try {
        this.isLoading = true;
        const _id = this.$route.params.id;
        const response = await this.$http.get(`student/my-packages/${_id}`);
        if (response.status === 200 || response.status === 201) {
          this.data = response.data.data;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
