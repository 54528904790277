<template>
  <div class="enrolled-course-card">
    <b-link
      class="nav-link"
      :to="{ name: 'ShowCourse', params: { id: item.id } }"
    >
      <div class="body-card">
        <div class="course-image">
          <img
            v-if="item.image && item.image.length"
            :src="item.image[0].path"
            :alt="`course ${item.name}`"
            class="img"
          />
          <img
            v-else
            src="@/assets/images/futuer-icons/logoMid.svg"
            :alt="`course ${item.name}`"
            class="img"
          />
        </div>
        <div class="course-content">
          <h4 class="course-name">{{ item.name }}</h4>

          <div class="course-range">
            <div class="course-percentage">
              <span class="percentage">{{ item.enrollments[0].progress }}</span>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :aria-valuenow="item.enrollments[0].progress"
                :aria-valuemin="item.enrollments[0].progress"
                aria-valuemax="100"
                :style="{ width: item.enrollments[0].progress }"
              />
            </div>
          </div>
        </div>
      </div>
    </b-link>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
  name: "Card",
  props: ["item"],
};
</script>

<style scoped lang="scss" src="./_card.scss"></style>
